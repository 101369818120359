import GridOnIcon from '@mui/icons-material/GridOn';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { loadingDemoDataList } from '../../api/deal';
import useLocalStorage from '../../utils/useLocalStorage';
import usePropState from '../../utils/usePropState';
import useListDeals from '../apiHooks/useListDeals';
import SectionOnlinePage from '../home/SectionOnlinePage';
import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
import Post from '../utils/Post';
import Filter from './Filter';
import FilterPills from './FilterPills';

function Deals() {
  const {
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    filter,
    setFilter,
    sortedDeals,
    loading,
    sortDeals,
    selectedCount,
    selectedFiltersCount,
    getListDeals,
    setPageSize,
  } = useListDeals();
  const [view, setView] = useLocalStorage('layout', 'grid');
  const [showFilter, setShowFilter] = useState(false);
  const [sortBy, setSortBy] = usePropState(filter.sort_by);

  const handleView = (event, value) => {
    setView(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showPosts = (items) => {
    return view === 'grid' ? (
      <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
          tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
      >
        {items.map((item, index) => (
          <Post item={item} orientation="vertical" disableBorder />
        ))}
      </div>
    ) : (
      <div className="tw-flex tw-flex-col tw-gap-3 xl:tw-gap-5 tw-w-full">
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
            <Post item={item} orientation="horizontal" />
          </Grid>
        ))}
      </div>
    );
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
    // sortDeals(event.target.value);
    const filterCopy = structuredClone(filter);
    filterCopy.sort_by = event.target.value;
    setFilter(filterCopy);
  };

  if (loading) {
    return (
      <>
        <SectionOnlinePage
          title="Online Deals"
          content={
            <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
              <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <TuneRoundedIcon
                        sx={{
                          color: selectedCount > 0 ? '#3054F5' : 'black',
                        }}
                      />
                    }
                    size="large"
                    color="black"
                    sx={{
                      height: { xs: 'undefined', md: '3.75rem' },
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <div
                      className={`tw-whitespace-nowrap ${
                        selectedCount > 0
                          ? 'tw-text-string-primary tw-font-semibold'
                          : 'tw-text-string-base'
                      }`}
                    >{`Filters (${selectedCount})`}</div>
                  </Button>
                </div>
                <Box
                  sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                >
                  <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                    <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                      0 deals
                    </p>

                    <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                      <div className="tw-flex-1">
                        <FormControl fullWidth size="small">
                          <Select value={sortBy} onChange={handleSortBy}>
                            <MenuItem value={'Most Recent'}>
                              Most Recent
                            </MenuItem>
                            <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                            <MenuItem value={'Percentage Off'}>
                              Percentage Off
                            </MenuItem>
                            <MenuItem value={'Trending Deals'}>
                              Trending Deals
                            </MenuItem>
                            {/*
                            <MenuItem value={'Alphabetically'}>
                              Alphabetically
                            </MenuItem>
                            */}
                            <MenuItem value={'Promo code included'}>
                              Promo code included
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <ToggleButtonGroup
                        size="small"
                        value={view}
                        exclusive
                        aria-label="view"
                        onChange={handleView}
                      >
                        <ToggleButton value="grid" aria-label="left aligned">
                          <GridOnIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="horizontal"
                          aria-label="left aligned"
                        >
                          <ViewHeadlineIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </Box>
                <Box
                  sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                >
                  <div className="tw-flex-1 tw-flex tw-gap-x-4">
                    <FormControl fullWidth size="small">
                      <Select value={sortBy} onChange={handleSortBy}>
                        <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                        <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                        <MenuItem value={'Percentage Off'}>
                          Percentage Off
                        </MenuItem>
                        <MenuItem value={'Trending Deals'}>
                          Trending Deals
                        </MenuItem>
                        {/*
                        <MenuItem value={'Alphabetically'}>
                          Alphabetically
                        </MenuItem>
                        */}
                        <MenuItem value={'Promo code included'}>
                          Promo code included
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <ToggleButtonGroup
                      size="small"
                      value={view}
                      exclusive
                      aria-label="view"
                      onChange={handleView}
                    >
                      <ToggleButton
                        value="grid"
                        aria-label="left aligned"
                      >
                        <GridOnIcon />
                      </ToggleButton>
                      <ToggleButton
                        value="horizontal"
                        aria-label="left aligned"
                      >
                        <ViewHeadlineIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Box>
              </div>
              <FilterPills
                filter={filter}
                setFilter={setFilter}
                count={selectedFiltersCount(filter)}
              />
              <CustomPaginationEndlessMode
                items={loadingDemoDataList}
                component={(items) => showPosts(items)}
                fetchNextPage={fetchNextPage}
                hasMore={hasNextPage}
              />
            </div>
          }
        />
        <Filter
          open={showFilter}
          filter={filter}
          onClose={() => setShowFilter(!showFilter)}
          setFilter={setFilter}
          selectedFiltersCount={selectedFiltersCount}
        />
      </>
    );
  }

  if (loading == false && sortedDeals && sortedDeals.length >= 0) {
    return (
      <div>
        <>
          <SectionOnlinePage
            title="Online Deals"
            content={
              <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
                <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                  <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={
                        <TuneRoundedIcon
                          sx={{
                            color: selectedCount > 0 ? '#398fed' : 'black',
                          }}
                        />
                      }
                      size="large"
                      color="black"
                      sx={{
                        height: { xs: 'undefined', md: '3.75rem' },
                      }}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <div
                        className={`tw-whitespace-nowrap ${
                          selectedCount > 0
                            ? 'tw-text-string-primary tw-font-semibold'
                            : 'tw-text-string-base'
                        }`}
                      >{`Filters (${selectedCount})`}</div>
                    </Button>
                  </div>
                  <Box
                    sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                  >
                    <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                      <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                        {data && data?.pages && data?.pages[0].items
                          ? sortedDeals.length
                          : 0}{' '}
                        deals
                      </p>

                      <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                        <div className="tw-flex-1 tw-flex tw-gap-x-4">
                          <FormControl fullWidth size="small">
                            <Select value={sortBy} onChange={handleSortBy}>
                              <MenuItem value={'Most Recent'}>
                                Most Recent
                              </MenuItem>
                              <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                              <MenuItem value={'Percentage Off'}>
                                Percentage Off
                              </MenuItem>
                              <MenuItem value={'Trending Deals'}>
                                Trending Deals
                              </MenuItem>
                              {/*
                              <MenuItem value={'Alphabetically'}>
                                Alphabetically
                              </MenuItem>
                              */}
                              <MenuItem value={'Promo code included'}>
                                Promo code included
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <ToggleButtonGroup
                          size="small"
                          value={view}
                          exclusive
                          aria-label="view"
                          onChange={handleView}
                        >
                          <ToggleButton value="grid" aria-label="left aligned">
                            <GridOnIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="horizontal"
                            aria-label="left aligned"
                          >
                            <ViewHeadlineIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                  >
                    <div className="tw-flex-1 tw-flex tw-gap-x-4">
                      <FormControl fullWidth size="small">
                        <Select value={sortBy} onChange={handleSortBy}>
                          <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                          <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                          <MenuItem value={'Percentage Off'}>
                            Percentage Off
                          </MenuItem>
                          <MenuItem value={'Trending Deals'}>
                            Trending Deals
                          </MenuItem>
                          {/*
                          <MenuItem value={'Alphabetically'}>
                            Alphabetically
                          </MenuItem>
                          */}
                          <MenuItem value={'Promo code included'}>
                            Promo code included
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <ToggleButtonGroup
                        size="small"
                        value={view}
                        exclusive
                        aria-label="view"
                        onChange={handleView}
                      >
                        <ToggleButton value="grid" aria-label="left aligned">
                          <GridOnIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="horizontal"
                          aria-label="left aligned"
                        >
                          <ViewHeadlineIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Box>
                </div>
                <FilterPills
                  filter={filter}
                  setFilter={setFilter}
                  count={selectedFiltersCount(filter)}
                />
                <CustomPaginationEndlessMode
                  items={sortedDeals}
                  component={(items) => showPosts(items)}
                  fetchNextPage={fetchNextPage}
                  hasMore={hasNextPage}
                />
              </div>
            }
          />
          <Filter
            open={showFilter}
            filter={filter}
            onClose={() => setShowFilter(!showFilter)}
            setFilter={setFilter}
            selectedFiltersCount={selectedFiltersCount}
          />
        </>
      </div>
    );
  }
}

export default Deals;
